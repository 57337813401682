<template>
  <v-container id="user" fluid tag="section">
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Order Id</b></v-col>
                <v-col cols="6" class="ml-3">{{ order_id }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Glugler Reference Id</b></v-col>
                <v-col cols="6" class="ml-3">{{ order_reference_id }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Name</b></v-col>
                <v-col cols="6" class="ml-3">{{ name }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Email</b></v-col>
                <v-col cols="6" class="ml-3">{{
                  email != undefined ? email : "Account Deactivated"
                }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Phone</b></v-col>
                <v-col cols="6" class="ml-3">{{
                  phone_number != undefined ? phone_number : "Account Deactivated"
                }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Order Status</b></v-col>
                <v-col cols="6" class="ml-3">{{ order_status }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Payment Status</b></v-col>
                <v-col cols="6" class="ml-3">{{ payment_status }}</v-col>
              </v-row>
              <v-row class="mt-0" v-if="nofraud_detail != null">
                <v-col cols="4" class="ml-5"><b>Nofraud Decision</b></v-col>
                <v-col cols="6" class="ml-3">{{ nofraud_detail.nf_decision }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Order is Gift</b></v-col>
                <v-col cols="6" class="ml-3">{{ isGift == true ? "YES" : "NO" }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Order is Pickup</b></v-col>
                <v-col cols="6" class="ml-3">{{ isPickup == true ? "YES" : "NO" }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Order Date</b></v-col>
                <v-col cols="6" class="ml-3">{{ format_date(order_date) }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Transaction Id</b></v-col>
                <v-col cols="6" class="ml-3">{{ transaction_id }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Price</b></v-col>
                <v-col cols="6" class="ml-3">{{ price }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Promocode</b></v-col>
                <v-col cols="6" class="ml-3">{{
                  promo_code_details != null ? promo_code_details.name_promocodes : ""
                }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Discount</b></v-col>
                <v-col cols="6" class="ml-3">{{ discount }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Delivery Charges</b></v-col>
                <v-col cols="6" class="ml-3">{{ delivery_charges }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Driver Tip</b></v-col>
                <v-col cols="6" class="ml-3">{{ driver_tip }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" class="ml-5"><b>Club Glugg Points Earned</b></v-col>
                <v-col cols="6" class="ml-3">{{ club_glugg_points }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" class="ml-5"><b>Age Verification Acknowledged </b></v-col>
                <v-col cols="6" class="ml-3">{{isAgeVerificationAcknowledged == true ? "YES" : "NO"}}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card v-if="customer_document != null">
            <v-card-title> Verified Document </v-card-title>
            <v-card-text>
              <v-img
                aspect-ratio="1"
                :src="imageUrl + customer_document"
                height="100"
                width="50"
                style="cursor: pointer"
                @click="openNewTab(imageUrl + customer_document)"
              />
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title> Delivery Partner Info</v-card-title>
            <v-card-text>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Driver Name</b></v-col>
                <v-col cols="6" class="ml-3">{{ driver_name }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Driver Phone </b></v-col>
                <v-col cols="6" class="ml-3">{{ driver_phone }}</v-col>
              </v-row>
              <v-row class="mt-0" v-if="isZifty == true || isDoordash == true">
                <v-col cols="4" class="ml-5"><b>Delivery Partner</b></v-col>
                <v-col v-if="isZifty == true" cols="6" class="ml-2">{{
                  isZifty == true ? "Zifty" : ""
                }}</v-col>
                <v-col v-else-if="isDoordash == true" cols="6" class="ml-2">{{
                  isDoordash == true ? "Doordash" : ""
                }}</v-col>
                <v-col v-else-if="isGluggler == true" cols="6" class="ml-2">{{
                  isGluggler == true ? "Gluggler" : ""
                }}</v-col>
              </v-row>
              <v-row class="mt-0" v-show="isZifty">
                <v-col cols="4" class="ml-5"><b>Delivery Confirmation Id</b></v-col>
                <v-col cols="6" class="ml-2">{{ ziftyConfirmationId }}</v-col>
              </v-row>
              <v-row class="mt-0" v-show="isDoordash">
                <v-col cols="4" class="ml-5"><b>Delivery Confirmation Id</b></v-col>
                <v-col cols="6" class="ml-2">{{ support_reference }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Delivery instructions</b></v-col>
                <v-col cols="6" class="ml-2">{{ delivery_instructions }}</v-col>
              </v-row>
              <!-- <v-row class="mt-0">
                <v-col cols="4" class="ml-5"><b>Gluggler Driver </b></v-col>
                <v-col cols="6" class="ml-3"
                  >{{ driver_detail.isGlugglerDriver == true ? "YES" : "NO" }}
                </v-col>
              </v-row> -->
            </v-card-text>
          </v-card>

          <v-card v-if="isPickup != true">
            <v-card-title> Delivery Address </v-card-title>
            <v-card-text v-if="delivery_address != undefined">
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>Unit#</b></v-col>
                <v-col class="ml-2" cols="8"> {{ delivery_address.block }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>BLDG</b></v-col>
                <v-col class="ml-2" cols="8">{{ delivery_address.floor }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>Building</b></v-col>
                <v-col class="ml-2" cols="8">{{ delivery_address.building_name }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>Landmark</b></v-col>
                <v-col class="ml-2" cols="8"> {{ delivery_address.landmark }}</v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>Street</b></v-col>
                <v-col class="ml-2" cols="8"> {{ delivery_address.street }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>City</b></v-col>
                <v-col class="ml-2" cols="8">{{ delivery_address.city }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"><b>Postcode</b></v-col>
                <v-col class="ml-2" cols="8">{{ delivery_address.postcode }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>Country</b></v-col>
                <v-col class="ml-2" cols="8"> {{ delivery_address.country }}</v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-if="delivery_address == undefined && isPickup != true">
              <v-row class="mt-0">
                <v-col class="ml-5">Account Deactivated</v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title> Billing Address </v-card-title>
            <v-card-text v-if="billing_address != undefined">
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>Unit#</b></v-col>
                <v-col class="ml-2" cols="8"> {{ billing_address.block }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>BLDG</b></v-col>
                <v-col class="ml-2" cols="8">{{ billing_address.floor }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>Building</b></v-col>
                <v-col class="ml-2" cols="8">{{ billing_address.building_name }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>Landmark</b></v-col>
                <v-col class="ml-2" cols="8"> {{ billing_address.landmark }}</v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>Street</b></v-col>
                <v-col class="ml-2" cols="8"> {{ billing_address.street }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>City</b></v-col>
                <v-col class="ml-2" cols="8">{{ billing_address.city }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"><b>Postcode</b></v-col>
                <v-col class="ml-2" cols="8">{{ billing_address.postcode }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="ml-2" cols="3"> <b>Country</b></v-col>
                <v-col class="ml-2" cols="8"> {{ billing_address.country }}</v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-if="billing_address == undefined">
              <v-row class="mt-0">
                <v-col class="ml-5">Account Deactivated</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
        :headers="columns"
        :items="orderItems"
        hide-default-footer
        disable-pagination
        class="elevation-1"
      >
        <template v-slot:[`item.product_name`]="{ item }">
          {{ item.product_name[0] ? item.product_name[0].name : "" }}
        </template>
        <template v-slot:[`item.variant_volume_type`]="{ item }">
          {{ item.product_name[0] ? item.product_name[0].product_variants[0].variant_volume_value + " " +  item.product_name[0].product_variants[0].variant_volume_type : "" }}
        </template>
        <template v-slot:[`item.total_price`]="{ item }">
          {{ item.product_price * item.quantity }}
        </template>
        <template slot="body.append">
          <tr>
            <th class="text-h4 font-weight">Total Product Price</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-h4 font-weight">{{ price }}</th>
          </tr>
          <tr>
            <th class="text-h4 font-weight">Tax</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-h4 font-weight">+{{ tax }}</th>
          </tr>
          <tr>
            <th class="text-h4 font-weight">Delivery Charges</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-h4 font-weight">+{{ delivery_charges }}</th>
          </tr>

          <tr>
            <th class="text-h4 font-weight">Discount</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-h4 font-weight">-{{ discount }}</th>
          </tr>
          <tr>
            <th class="text-h4 font-weight">Driver Tip</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-h4 font-weight">+{{ driver_tip }}</th>
          </tr>
          <tr>
            <th class="text-h4 font-weight">Grand Total</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-h4 font-weight">{{ grandTotal }}</th>
          </tr>
        </template>
      </v-data-table>
      <v-row class="mt-3">
        <v-col cols="12" class="text-left">
          <router-link :to="{ name: 'Orders' }">
            <v-btn type="button" color="primary" class="mr-0">Back</v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  props: ["id", "action"],
  data() {
    return {
      editUser: false,
      onlyView: false,
      isZifty: false,
      isDoordash: false,
      isGluggler: false,
      name: "",
      email: "",
      phone_number: "",
      order_status: "",
      driver_name: "",
      driver_phone: "",
      support_reference: "",
      ziftyConfirmationId: "",
      payment_status: "",
      delivery_instructions: "",
      isGift: "",
      isPickup: "",
      order_date: "",
      transaction_id: "",
      price: "",
      discount: "",
      delivery_charges: "",
      driver_tip: "",
      delivery_address: "",
      billing_address: null,
      grandTotal: "",
      tax: "",
      orderItems: [],
      order_id: null,
      order_reference_id: null,
      promo_code_details: null,
      customer_document: null,
      imageUrl: process.env.VUE_APP_Image_Link + "Orders/",
      club_glugg_points: 0,
      isAgeVerificationAcknowledged: "",
      driver_detail: null,
      nofraud_detail: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          sortable: false,
          text: "Product Name",
          value: "product_name",
          sortable: false,
        },
        { sortable: false, text: "SKU", value: "sku_number", sortable: false },
        { sortable: false, text: "UPC", value: "upc_number", sortable: false },
        {
          sortable: false,
          text: "Product Price",
          value: "product_price",
          sortable: false,
        },
        {
          sortable: false,
          text: "Quantity",
          value: "quantity",
          sortable: false,
        },
        {
          sortable: false,
          text: "Product Size",
          value: "variant_volume_type",
          sortable: false,
        },
        {
          sortable: false,
          text: "Total Price",
          value: "total_price",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    getOrderData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "orders/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.orders.user_name.length > 0) {
              this.name =
                response.data.orders.user_name[0].first_name +
                " " +
                response.data.orders.user_name[0].last_name;
              this.email = response.data.orders.user_name[0].email;
              this.phone_number = response.data.orders.user_name[0].phone_number;
            } else {
              this.name = undefined;
              this.email = undefined;
              this.phone_number = undefined;
            }

            this.order_status = response.data.orders.order_status;
            this.payment_status = response.data.orders.payment_status;
            this.isGift = response.data.orders.isGift;
            this.isPickup = response.data.orders.isPickup;
            this.isAgeVerificationAcknowledged = response.data.orders.isAgeVerificationAcknowledged;
            this.order_date = response.data.orders.order_date;
            this.transaction_id = response.data.orders.transaction_id;
            this.price = response.data.orders.price;
            this.isZifty = response.data.orders.isZifty;
            this.isDoordash = response.data.orders.isDoordash;
            this.isGluggler = response.data.orders.isGluggler;
            this.grandTotal = response.data.orders.grant_total;
            this.tax = response.data.orders.tax;
            this.driver_name = response.data.orders.driver_name;
            this.driver_phone = response.data.orders.driver_phone;
            this.support_reference = response.data.orders.support_reference;
            this.ziftyConfirmationId = response.data.orders.ziftyConfirmationId;
            this.discount = response.data.orders.discount;
            this.delivery_charges = response.data.orders.delivery_charges;
            this.driver_tip = response.data.orders.driver_tip;
            this.customer_document = response.data.orders.customer_document;
            this.orderItems = response.data.orders.order_items;
            if (response.data.orders.user_info.length > 0) {
              this.delivery_address = response.data.orders.ship_info[0];
            } else {
              this.delivery_address = undefined;
            }

            if (response.data.orders.billing_info.length > 0) {
              this.billing_address = response.data.orders.billing_info[0];
            } else {
              this.billing_address = undefined;
            }
            this.order_id = response.data.orders.id;
            this.order_reference_id = response.data.orders._id;
            this.promo_code_details = response.data.orders.promo_code[0];
            this.delivery_instructions = response.data.orders.delivery_instructions;
            // this.driver_detail =
            //   response.data.orders.driver_name != null &&
            //   response.data.orders.driver_name.length > 0
            //     ? response.data.orders
            //     : null;
            this.orderItems = response.data.orders.order_items;

            for (let index = 0; index < this.orderItems.length; index++) {
              const element = this.orderItems[index];
              this.calculateClubGluggPoints(element.club_glugg_points);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    format_date(value) {
      if (value) {
        var res = new Date(value * 1000);
        return moment(res).format("DD/MM/YYYY h:m:s A");
      }
    },
    calculateClubGluggPoints(point) {
      if (point != null && point != undefined && point != NaN) {
        this.club_glugg_points = this.club_glugg_points + point;
      }
    },
    getNofraudStatus(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "orders/nofraudStatus/" + id)
        .then((response) => {
          this.nofraud_detail = response.data.Data;
          console.log(response);
        });
    },
  },
  mounted() {
    if (this.id != undefined) {
      this.getNofraudStatus(this.id);
      this.getOrderData(this.id);
      this.editUser = true;
    } else {
      this.editUser = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  },
};
</script>
